<template>
  <div class="fix-height home__top-banner">
    <div class="hero-banner">
      <Swiper ref="banner" v-bind="swiperOption" class="mySwiper">
        <SwiperSlide v-for="(item, key) in bannerData" :key="key" class="swiper-slide">
          <BannerItem :item="item" :index="key" @click="handleClickBanner(item)" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay, Pagination, EffectCoverflow } from 'swiper'
import BannerItem from '~/components/desktop/pages/home/banner/banner-item.vue'
import useHeroBanner from '~/composables/home/useHeroBanner'
const { bannerData, fetchBanner, handleClickBanner } = useHeroBanner()
const swiperOption = reactive({
  grabCursor: true,
  initialSlide: 0,
  observer: true,
  loop: true,
  slidesPerView: 1,
  slidesPerGroup: 1,
  autoplay: {
    delay: 5000,
    disableOnInteraction: false
  },
  centeredSlides: true,
  spaceBetween: 0,
  pagination: {
    clickable: true
  },
  modules: [Autoplay, Pagination, EffectCoverflow]
})

useAsyncData(async () => {
  await fetchBanner()
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/banner/index.scss"></style>
