<template>
  <div class="section-lobby container-custom">
    <div class="section-lobby__title">
      <BaseImg
        src="/assets/images/components/desktop/pages/home/section-lobby/icon-lobby.svg"
        alt="ic-sport"
        class="ic-sport"
      />
      <h2>Danh mục trò chơi</h2>
    </div>
    <div class="section-lobby__content">
      <div v-for="(item, index) in LOBBY_GAMES" :key="index" class="item-game">
        <div v-if="item.jackpot && sumJackpot[item.jackpot] > 0" class="jackpot">
          <AnimateCountUp
            :number="sumJackpot[item.jackpot]"
            :show-coin="false"
          />
        </div>
        <nuxt-link class="link-game" :to="item.link">
          <span class="title">{{ item.title }}</span>
          <BaseImg :src="item.img" :alt="item.title" lazy class="game" />
          <div class="gradient">
            <BaseImg
              src="/assets/images/components/desktop/pages/home/section-lobby/elipse-1.svg"
              alt="ellipse 1"
              class="ellipse-1"
              lazy
            />
            <BaseImg
              src="/assets/images/components/desktop/pages/home/section-lobby/elipse-2.svg"
              alt="ellipse 2"
              class="ellipse-2"
              lazy
            />
          </div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_GAMES } from '~/resources/home/section-lobby'
import { useJackpot } from '~/composables/game/useJackpot'
import AnimateCountUp from '~/components/common/animate-count-up.vue'
import { useAppStore } from '~/store/app'
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)

</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/section-lobby/index.scss"></style>
