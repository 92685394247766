<template>
  <div class="section-sports container-custom">
    <div class="section-sports__title">
      <BaseImg
        src="/assets/images/components/desktop/pages/home/section-sports/icon-sport.svg"
        class="ic-sport"
        alt="ic-sport"
      />
      <h2>Cá cược thể thao</h2>
    </div>
    <div class="section-sports__content">
      <nuxt-link
        v-for="(item, index) in LOBBY_SPORTS.slice(0, 4)"
        :key="index"
        class="section-sports__left--item"
        :to="item.link"
      >
        <BaseImg :src="item.background" :alt="item.name" lazy class="background" />
        <BaseImg :src="item.img" :alt="item.name" lazy class="item" />
        <BaseImg :src="item.title" :alt="item.name" lazy :class="item.class" class="title" />
      </nuxt-link>
      <div class="section-sports__right">
        <nuxt-link
          v-for="(item, index) in LOBBY_SPORTS.slice(4, 6)"
          :key="index"
          class="section-sports__right--item"
          :to="item.link"
        >
          <BaseImg :src="item.background" :alt="item.name" lazy class="background" />
          <BaseImg :src="item.img" :alt="item.name" lazy class="item" :class="item.class" />
          <BaseImg :src="item.title" :alt="item.name" lazy class="title" :class="item.class" />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseImg from '~/components/common/base-img.vue'
import { LOBBY_SPORTS } from '~/resources/home/section-sports'
const router = useRouter()
const openLink = (link: string) => {
  router.push(link)
}
</script>
<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/section-sports/index.scss"></style>
