<template>
  <div class="section-hot">
    <div class="section-hot__title">
      <BaseImg
        src="/assets/images/components/desktop/pages/home/section-jackpot/icon-hot.png"
        alt="ic-sport"
        class="ic-sport"
      />
      <h2>Game hot</h2>
    </div>
    <div class="section-hot__content">
      <div
        v-for="(item, index) in listGame"
        :key="index"
        class="item-game"
        @click="navigationCheckLoggedInAndOpenGame(item)"
      >
        <BaseImg :src="item.img" :alt="item.name" lazy class="image-game" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import useNavigationGame from '~/composables/useNavigationGame'
import { useGameHot } from '~/composables/home/useGameHot'

const { navigationCheckLoggedInAndOpenGame } = useNavigationGame()
const { listGame } = useGameHot()
</script>

<style scoped lang="scss" src="assets/scss/components/mobile/pages/home/section-hot/index.scss"></style>
