interface ILobbySport {
  background: string
  img?: string
  title?: string
  name?: string
  class?: string
  link: string
}
export const LOBBY_SPORTS: ILobbySport[] = [
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-ksport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/ksport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/ksports.webp',
    name: 'ksports',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-asport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/asport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/asports.webp',
    name: 'asports',
    link: '/the-thao/asports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-psport.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/psport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/psports.webp',
    name: 'psport',
    link: '/the-thao/psports',
    class: 'psport'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-esports.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/esports-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/vitual-sports.webp',
    name: 'the-thao-ao',
    link: '/the-thao/the-thao-ao',
    class: 'esport'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-volta.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/volta-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/volta.webp',
    name: 'ksports',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/desktop/pages/home/section-sports/bg-volta.webp',
    img: '/assets/images/components/desktop/pages/home/section-sports/esport-item.webp',
    title: '/assets/images/components/desktop/pages/home/section-sports/esports.webp',
    name: 'esports',
    link: '/the-thao/the-thao-dien-tu',
    class: 'esports'
  }
]

export const LOBBY_SPORTS_MB: ILobbySport[] = [
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/k-sports.webp',
    name: 'ksports',
    link: '/the-thao/ksports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/a-sports.webp',
    name: 'asports',
    link: '/the-thao/aports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/p-sports.webp',
    name: 'psports',
    link: '/the-thao/psports'
  },
  {
    background: '/assets/images/components/mobile/pages/home/section-sport/virtual-sports.webp',
    name: 'the-thao-ao',
    link: '/the-thao/the-thao-ao'
  }
]
