import { useLobbyGameApi } from '~/api/lobby-game'
import { IGameItem } from '~/types/common'
import { RESPONSE_STATUS } from '~/config/constant'

export const useGameHot = () => {
  const { $axios, $device } = useNuxtApp()
  const { LIST_LOBBY_GAME } = useLobbyGameApi()

  const page = ref<number>(1)
  const limit = ref<number>($device.isDesktop ? 5 : 6)
  const listGame = ref<IGameItem[]>([])

  const fetchListGameHot = async () => {
    try {
      const payload = {
        params: {
          page: page.value,
          limit: limit.value,
          path_alias: 'all',
          sort_by: 'HOT_GAME'
        }
      }

      const { data: response } = await $axios.get(LIST_LOBBY_GAME, payload)
      if (response.status === RESPONSE_STATUS.STATUS_OK) {
        listGame.value = response.data?.items
      }
    } catch (error) {
      console.log('error', error)
    }
  }

  useAsyncData(async () => {
    await fetchListGameHot()
  })

  return {
    listGame,
    fetchListGameHot
  }
}
