<template>
  <div class="home">
    <Banner />
    <LazyHydrationWrapper when-visible>
      <SectionSports />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionLobby />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionJackpot />
    </LazyHydrationWrapper>
    <LazyHydrationWrapper when-visible>
      <SectionHot />
    </LazyHydrationWrapper>
    <ContentSeo />
    <div v-if="isShowScroll" class="home-scroll-top">
      <BaseImg
        src="/assets/images/common/icon-top.svg"
        @click="scrollToTop()"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import { storeToRefs } from 'pinia'
import Banner from '~/components/desktop/pages/home/banner/index.vue'
import SectionSports from '~/components/desktop/pages/home/section-sports/index.vue'
import SectionLobby from '~/components/desktop/pages/home/section-lobby/index.vue'
import SectionJackpot from '~/components/desktop/pages/home/section-jackpot/index.vue'
import SectionHot from '~/components/desktop/pages/home/section-hot/index.vue'

import { useAppStore } from '~/store/app'

const ContentSeo = defineAsyncComponent(() => import('@/components/common/seo-content.vue'))
const { $config } = useNuxtApp()

const isShowScroll = ref<boolean>(false)
const userScroll = () => {
  isShowScroll.value = window.scrollY > 0
}

onMounted(() => {
  window.addEventListener('scroll', userScroll)
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', userScroll)
})

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth'
  })
}
const { $pinia } = useNuxtApp()
const store = useAppStore($pinia)
const { seoData } = storeToRefs(store)
const route = useRoute()
const dataSeo = computed(() => {
  return seoData.value.find((item) => item.alias === '/')
})
</script>

<style scoped lang="scss" src="assets/scss/components/desktop/pages/home/index.scss"></style>
